import { Component } from 'react';
import { TextField,ImageList, ImageListItem, Button, Select, MenuItem, FormControl  } from '@mui/material';

import Talent from '../../Components/Talent';
import * as talentTypes from '../../Components/TalentTypes';

import './TalentEditor.scss';

const defaultTalents = {
    "1": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:0",
        "image": 0,
        "state": "unlocked",
        "type": "normal"
    },
    "2": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "3": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "4": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "5": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "6": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "7": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "core",
        "image": 0,
        "state": "locked",
        "type": "core"
    },
    "8": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:0",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "9": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "10": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "11": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "12": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "13": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "14": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "15": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "top:3",
        "image": 0,
        "state": "locked",
        "type": "elite"
    },
    "16": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "top:3",
        "image": 0,
        "state": "locked",
        "type": "elite"
    },
    "17": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:4",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "18": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:4",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "19": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:4",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "20": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:5",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "21": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:5",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "22": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:5",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "23": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:6",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "24": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:6",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "25": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "top:6",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "26": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "top:7",
        "image": 0,
        "state": "locked",
        "type": "core"
    },
    "27": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "top:7",
        "image": 0,
        "state": "locked",
        "type": "core"
    },
    "28": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:0",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "29": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "30": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "31": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "32": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "33": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "34": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "35": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "left:3",
        "image": 0,
        "state": "locked",
        "type": "elite"
    },
    "36": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "left:3",
        "image": 0,
        "state": "locked",
        "type": "elite"
    },
    "37": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:4",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "38": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:4",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "39": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:4",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "40": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:5",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "41": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:5",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "42": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:5",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "43": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:6",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "44": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:6",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "45": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "left:6",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "46": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "left:7",
        "image": 0,
        "state": "locked",
        "type": "core"
    },
    "47": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "left:7",
        "image": 0,
        "state": "locked",
        "type": "core"
    },
    "48": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:0",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "49": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "50": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "51": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:1",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "52": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "53": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "54": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:2",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "55": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "right:3",
        "image": 0,
        "state": "locked",
        "type": "elite"
    },
    "56": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "right:3",
        "image": 0,
        "state": "locked",
        "type": "elite"
    },
    "57": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:4",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "58": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:4",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "59": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:4",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "60": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:5",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "61": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:5",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "62": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:5",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "63": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:6",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "64": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:6",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "65": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 5,
        "section": "right:6",
        "image": 0,
        "state": "locked",
        "type": "normal"
    },
    "66": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "right:7",
        "image": 0,
        "state": "locked",
        "type": "core"
    },
    "67": {
        "name": "Talent",
        "description": "Description",
        "points": 0,
        "maxPoints": 1,
        "section": "right:7",
        "image": 0,
        "state": "locked",
        "type": "core"
    }
}

// const baseURL = 'https://cod.jsfour.dev';
const baseURL = 'http://192.168.10.15:80/_websites';

class TalentEditor extends Component {
    constructor( props ) {
        super(props);
        this.state = {
            heroes: {},
            talents: {
                "1": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:0",
                    "image": 0,
                    "state": "unlocked",
                    "type": "normal"
                },
                "2": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "3": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "4": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "5": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "6": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "7": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "core",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "8": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:0",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "9": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "10": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "11": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "12": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "13": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "14": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "15": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "top:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "16": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "top:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "17": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "18": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "19": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "20": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "21": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "22": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "23": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "24": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "25": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "26": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "top:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "27": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "top:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "28": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:0",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "29": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "30": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "31": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "32": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "33": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "34": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "35": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "left:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "36": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "left:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "37": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "38": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "39": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "40": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "41": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "42": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "43": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "44": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "45": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "46": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "left:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "47": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "left:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "48": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:0",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "49": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "50": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "51": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "52": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "53": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "54": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "55": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "right:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "56": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "right:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "57": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "58": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "59": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "60": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "61": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "62": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "63": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "64": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "65": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "66": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "right:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "67": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "right:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                }
            },
            trees: {
                'top': {
                    text: 'Skills',
                    points: 0,
                    pointsToComplete: 32,
                    image: 'Skills'
                },
                'left': {
                    text: 'Skills',
                    points: 0,
                    pointsToComplete: 32,
                    image: 'Skills'
                },
                'right': {
                    text: 'Skills',
                    points: 0,
                    pointsToComplete: 32,
                    image: 'Skills'
                },
                'core': {
                    points: 0,
                    pointsToComplete: 1,
                    image: ''
                },
                'foundation': {
                    text: 'Foundation Talents',
                    points: 0,
                    pointsToComplete: 9,
                    image: 'Foundation'
                }
            },
            sections: {
                'foundation:0': {
                    points: 0,
                    maxPoints: 3,
                    requiredPoints: 3,
                    nextSection: ['foundation:1']
                },
                'foundation:1': {
                    points: 0,
                    maxPoints: 6,
                    requiredPoints: 3,
                    nextSection: ['foundation:2']
                },
                'foundation:2': {
                    points: 0,
                    maxPoints: 9,
                    requiredPoints: 3,
                    nextSection: ['core']
                },
                'core': {
                    points: 0,
                    maxPoints: 1,
                    requiredPoints: 1,
                    nextSection: ['left:0', 'top:0', 'right:0']
                },
                'top:0': {
                    points: 0,
                    maxPoints: 5,
                    requiredPoints: 5,
                    nextSection: ['top:1']
                },
                'top:1': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:2']
                },
                'top:2': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:3']
                },
                'top:3': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: ['top:4']
                },
                'top:4': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:5']
                },
                'top:5': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:6']
                },
                'top:6': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:7']
                },
                'top:7': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: []
                },
                'left:0': {
                    points: 0,
                    maxPoints: 5,
                    requiredPoints: 5,
                    nextSection: ['left:1']
                },
                'left:1': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:2']
                },
                'left:2': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:3']
                },
                'left:3': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: ['left:4']
                },
                'left:4': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:5']
                },
                'left:5': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:6']
                },
                'left:6': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:7']
                },
                'left:7': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: []
                },
                'right:0': {
                    points: 0,
                    maxPoints: 5,
                    requiredPoints: 5,
                    nextSection: ['right:1']
                },
                'right:1': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:2']
                },
                'right:2': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:3']
                },
                'right:3': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: ['right:4']
                },
                'right:4': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:5']
                },
                'right:5': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:6']
                },
                'right:6': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:7']
                },
                'right:7': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: []
                }
                
            },
            zoom: 40,
            mouse: {
                down: false,
                container: {
                    x: 0,
                    y: 0
                }
            },
            talentImages: [],
            talentCard: {
                name: 0,
                description: 0,
                image: 0,
                id: 0
            },
            heroSelect: {
                id: 0,
                name: 0,
                faction: 0,
                nickname: 0,
                rarity: 0
            },
            treeCard: {
                text: 0,
                image: 0,
                direction: 0
            },
            allTrees: talentTypes.all
        }
    }
   
    componentDidMount() {
        fetch(`${baseURL}/api/v1/hero.php`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                ...prevState,
                heroes: data
            }));
        });

        fetch(`${baseURL}/api/v1/images.php`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                ...prevState,
                talentImages: data
            }));
        }); 
    }

    getHero( id ) {
        fetch(`${baseURL}/api/v1/hero.php?id=${ id }`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                ...prevState,
                talents: JSON.parse(data[0].talents),
                heroSelect: {
                    id: data[0].id,
                    name: data[0].name,
                    faction: data[0].faction,
                    nickname: data[0].nick_name,
                    rarity: data[0].rarity
                }
            }));

            let trees = JSON.parse(data[0].talent_trees);

            Object.keys( trees ).forEach(tree => {
                this.setState(prevState => ({
                    ...prevState,
                    trees: trees
                }));
            });
        });
    }

    openTalent( e, id, imageID ) {
        this.setState(prevState => ({
            ...prevState,
            talentCard: {
                ...prevState.talentCard,
                name: this.state.talents[id].name,
                description: this.state.talents[id].description,
                image: imageID,
                id: id
            }
        }));

        if ( document.querySelector('.selectedTalent') ) {
            document.querySelector('.selectedTalent').classList.toggle('selectedTalent');
        }
        
        document.querySelector(`.talent-image-${ imageID }`).classList.toggle('selectedTalent');
    }

    textFieldOnChange( e, field ) {
        this.setState(prevState => ({
            ...prevState,
            talentCard: {
                ...prevState.talentCard,
                [field]: e.target.value
            }
        }));
    }

    getTalents( tree ) {
        let talents = [];

        Object.keys( this.state.talents ).forEach(( id ) => {
            let talent = this.state.talents[id];
            let talentTree = talent.section.replace(/:.*/gm, '');
            
            if ( tree === talentTree && Object.keys(this.state.talentImages).length) {
                talents.push(
                    <Talent
                        section={talent.section}
                        id={id}
                        type={talent.type}
                        state={talent.state}
                        points={talent.points}
                        maxPoints={talent.maxPoints}
                        openTalent={(e) => this.openTalent(e, id, talent.image)}
                        image={'data:image/png;base64, ' + this.state.talentImages[talent.image]}
                    />
                );
            }
        });

        return talents;
    }

    selectTalent( e, id ) {
        document.querySelector('.selectedTalent').classList.toggle('selectedTalent');
        e.target.classList.toggle('selectedTalent');
        
        this.setState(prevState => ({
            ...prevState,
            talentCard: {
                ...prevState.talentCard,
                image: id
            }
        }));
    }

    saveTalent( e ) {
        this.setState(prevState => ({
            ...prevState,
            talents: {
                ...prevState.talents,
                [this.state.talentCard.id]: {
                    ...prevState.talents[this.state.talentCard.id],
                    name: this.state.talentCard.name,
                    description: this.state.talentCard.description,
                    image: this.state.talentCard.image
                }
            },
            talentCard: {
                ...prevState.talentCard,
                name: 0
            }
        }));

        this.saveHero();
    }

    saveHero() {
        fetch(`${baseURL}/api/v1/hero.php`, {
            method: 'POST',
            mode: 'cors',
            // headers: {
            //     "Content-Type": "application/json",
            // },
            body: JSON.stringify({
                'id': this.state.heroSelect.id,
                'name': this.state.heroSelect.name,
                'faction': this.state.heroSelect.faction,
                'nick_name': this.state.heroSelect.nickname,
                'rarity': this.state.heroSelect.rarity,
                'talent_trees': JSON.stringify(this.state.trees),
                'talents': JSON.stringify(this.state.talents)
            })
        })
        .then(response => response.text())
        .then(data => {
            console.log(data);
        });
    }

    selectHero( e ) {
        this.setState(prevState => ({
            ...prevState,
            talents: defaultTalents
        }));

        if( e.target.value !== 'new' && e.target.value !== 0 ) {
            this.getHero(e.target.value);
        } else {
            this.setState(prevState => ({
                ...prevState,
                heroSelect: {
                    ...prevState.heroSelect,
                    id: e.target.value
                }
            }));
        } 
    }
    
    heroFieldChange( e, field ) {
        this.setState(prevState => ({
            ...prevState,
            heroSelect: {
                ...prevState.heroSelect,
                [field]: e.target.value
            }
        }));
    }

    openTree( tree ) {
        this.setState(prevState => ({
            ...prevState,
            treeCard: {
                text: this.state.trees[tree].text,
                image: this.state.trees[tree].image,
                direction: tree
            }
        }));
    }

    selectTree( e ) {
        this.setState(prevState => ({
            ...prevState,
            treeCard: {
                ...prevState.treeCard,
                text: e.target.value,
                image: e.target.value
            }
        }));
    }

    saveTree() {
        this.setState(prevState => ({
            ...prevState,
            trees: {
                ...prevState.trees,
                [this.state.treeCard.direction]: {
                    ...prevState.trees[this.state.treeCard.direction],
                    text: this.state.treeCard.text,
                    image: this.state.treeCard.image
                }
            }
        }));

        this.setState(prevState => ({
            ...prevState,
            treeCard: {
                text: 0,
                image: 0,
                direction: 0
            }
        }));
    }

    render() {
        return (
            <div id="talent-editor">
                <div id="hero-select">
                    <FormControl variant="filled">
                        <Select
                            labelId="demo-simple-select-label"
                            value={this.state.heroSelect.id}
                            label="Hero"
                            onChange={(e) => this.selectHero(e)}
                            sx={{ m: 1, width: 300 }}
                        >
                            <MenuItem value={0}>Select Hero</MenuItem>
                            <MenuItem value='new'>Create new</MenuItem>
                            { Object.keys(this.state.heroes).map((hero) => (
                                <MenuItem value={this.state.heroes[hero].id}>{this.state.heroes[hero].name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> 
                </div>
                <div id="new-hero">
                    {
                        this.state.heroSelect.id === 'new' || this.state.heroSelect.id > 0
                        ? (
                            <>
                                <TextField onChange={(e) => this.heroFieldChange(e, 'name')} id="new-hero-name" sx={{ m: 1, width: 300 }} label="Name" variant="outlined" value={this.state.heroSelect.name} />
                                <TextField onChange={(e) => this.heroFieldChange(e, 'faction')} id="new-hero-faction" sx={{ m: 1, width: 300 }} label="Faction" variant="outlined" value={this.state.heroSelect.faction} />
                                <TextField onChange={(e) => this.heroFieldChange(e, 'nickname')} id="new-hero-nickname" sx={{ m: 1, width: 300 }} label="Nick name" variant="outlined" value={this.state.heroSelect.nickname} />
                                <TextField onChange={(e) => this.heroFieldChange(e, 'rarity')} id="new-hero-rarity" sx={{ m: 1, width: 300 }} label="Rarity" variant="outlined" value={this.state.heroSelect.rarity} />
                            </>
                        ) : (
                            <></>
                        )
                    }
                </div>
                <div id="talent-card" className={ (this.state.talentCard.name ? '' : 'hideCard' ) }>
                    <TextField 
                        id="name" 
                        label="Name" 
                        variant="outlined"
                        value={this.state.talentCard.name}
                        onChange={(e) => this.textFieldOnChange(e, 'name')}
                        fullWidth 
                    />
                    <TextField 
                        id="description" 
                        label="Description" 
                        variant="outlined"
                        value={this.state.talentCard.description}
                        onChange={(e) => this.textFieldOnChange(e, 'description')}
                        multiline
                        fullWidth 
                    />
                    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                        { Object.keys(this.state.talentImages).map((item) => (
                            <ImageListItem key={item}>
                            <img
                                src={'data:image/png;base64, ' + this.state.talentImages[item]}
                                alt={item}
                                loading="lazy"
                                title={item}
                                onClick={(e) => this.selectTalent(e, item)}
                                className={ 'talent-image-' + item }
                            />
                            </ImageListItem>
                        ))}
                    </ImageList>
                    <Button variant="contained" color="success" fullWidth onClick={(e) => this.saveTalent(e)}>
                        Done
                    </Button>
                </div>
                <div id="tree-card" className={ (this.state.treeCard.text ? '' : 'hideTree' ) }>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-label"
                            value={this.state.treeCard.text}
                            label="Hero"
                            onChange={(e) => this.selectTree(e)}
                            sx={{ m: 1, width: 300 }}
                        >
                            { Object.keys(this.state.allTrees).sort().map((tree) => (
                                <MenuItem value={tree}>{tree}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> 
                    <Button variant="contained" color="success" fullWidth onClick={(e) => this.saveTree(e)}>
                        Done
                    </Button>
                </div>
                <div id="talent-tree">
                    {Object.keys( this.state.trees ).map((key) => {
                        return (
                            <div key={key} id={key}>
                                {
                                this.state.trees[key].text
                                    ? (
                                        <div className="talent-section-header">
                                            <div className='talent-section-image' onClick={(e) => this.openTree(key)}>
                                                <img src={talentTypes[this.state.trees[key].image]} alt="Talent type" className={ 'talent-type ' + this.state.trees[key].image } />
                                                <img src={talentTypes.border} alt="Talent border" className='talent-border' />
                                            </div>
                                            <h1>{this.state.trees[key].text}</h1>
                                        </div>
                                    )
                                    :  ( <></> )
                                }
                                <div className='talents'>
                                    { this.getTalents( key ) }
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Button variant="contained" color="success" id="save-hero-button" onClick={(e) => this.saveHero()}>
                    Save Hero
                </Button>
            </div>
        );
    }
}

export default TalentEditor;