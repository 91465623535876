import React from 'react';
import { 
    CssBaseline,
    ThemeProvider, 
    createTheme,
    Button,
    TextField,
    Grid
} from '@mui/material';

import './Login.scss';

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    }
});

// const baseURL = 'https://cod.jsfour.dev/';
const baseURL = 'http://192.168.10.15:80/_websites/';

// sessionStorage.setItem("key1", "value2")

class Login extends React.Component {
    constructor( props ) {
        super(props);
        this.state = {
            key: null
        }
    }

    componentDidMount() {
        document.title = `Login`;
    }

    componentWillUnmount() {

    }

    setMagicWord( e ) {
        this.setState(prevState => ({
            ...prevState,
            key: e.target.value
        }));
    }

    login() {
        if ( this.state.key === undefined || this.state.key === null || this.state.key === '' || this.state.key.length === 0 ) return;

        fetch(`${baseURL}/api/v1/master.php`, {
            method: 'POST',
            body: JSON.stringify({
                'key': this.state.key
            })
        })
        .then(response => response.text())
        .then(data => {
            console.log('return:', data);
        });
    }

    render() {
        return (
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <div id="login">
                    <Grid 
                        container 
                    >
                        <Grid item xs={12}>
                            <TextField 
                                id="outlined-basic" 
                                label="Magic word" 
                                variant="outlined"
                                fullWidth
                                type="password"
                                onChange={(e) => this.setMagicWord(e)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={() => this.login()} size="large" fullWidth variant="contained">Enter</Button>
                        </Grid>
                    </Grid>  
                </div>
            </ThemeProvider>
        );
    }
}

export default Login;