import { Component } from 'react';

import JSONEditorReact from './Editor.js';

import './Handler.scss';

const defaultTalentTree = {
    "1": {
        "name": "Overall Attack",
        "description": "Increases the ATK of your Legion's units by 1.2%.",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:0",
        "image": 17,
        "state": "unlocked",
        "type": "normal"
    },
    "2": {
        "name": "Overall Speed",
        "description": "Increases the March Speed of your Legion's units by 6%.",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:1",
        "image": 44,
        "state": "locked",
        "type": "normal"
    },
    "3": {
        "name": "Overall Defense",
        "description": "Increases the DEF of your Legion's units by 0.4%.",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:1",
        "image": 10,
        "state": "locked",
        "type": "normal"
    },
    "4": {
        "name": "Logistics Master",
        "description": "Increases your Legion's Gather Speed by 3%.",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:2",
        "image": 8,
        "state": "locked",
        "type": "normal"
    },
    "5": {
        "name": "Overall Health",
        "description": "Increases the HP of your Legion's units by 1.2%.",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:2",
        "image": 50,
        "state": "locked",
        "type": "normal"
    },
    "6": {
        "name": "Bane of Darkness",
        "description": "Your Legion deals 1% more damage to Darklings and Dark Creatures.",
        "points": 0,
        "maxPoints": 3,
        "section": "foundation:2",
        "image": 7,
        "state": "locked",
        "type": "normal"
    },
    "7": {
        "name": "Mighty Power",
        "description": "Your Legion deals 2% more Hero Skill damage, and has a 10% chance to gain 20 extra Rage.",
        "points": 0,
        "maxPoints": 1,
        "section": "core",
        "image": 14,
        "state": "locked",
        "type": "core"
    },
    "8": {
        "name": "Overall Attack",
        "description": "Increases the ATK of your Legion's units by 2%.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:0",
        "image": 17,
        "state": "locked",
        "type": "normal"
    },
    "9": {
        "name": "Overall Defense",
        "description": "Increases the DEF of your Legion's units by 0.4%.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:1",
        "image": 10,
        "state": "locked",
        "type": "normal"
    },
    "10": {
        "name": "Overall Speed",
        "description": "Increases the March Speed of your Legion's units by 2%.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:1",
        "image": 44,
        "state": "locked",
        "type": "normal"
    },
    "11": {
        "name": "Rivalry",
        "description": "Your Legion deasl 1% more normal attack damage in the field.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:1",
        "image": 15,
        "state": "locked",
        "type": "normal"
    },
    "12": {
        "name": "Overall Health",
        "description": "Increases the HP of your Legion's units by 0.4%",
        "points": 0,
        "maxPoints": 5,
        "section": "top:2",
        "image": 50,
        "state": "locked",
        "type": "normal"
    },
    "13": {
        "name": "Fury Flame",
        "description": "Your Legion deals 1% more Hero Skill damage in the field.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:2",
        "image": 36,
        "state": "locked",
        "type": "normal"
    },
    "14": {
        "name": "High Spirits",
        "description": "Launching a normal attack has a 10% chance to grant your Legion 10 Rage.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:2",
        "image": 36,
        "state": "locked",
        "type": "normal"
    },
    "15": {
        "name": "Army of Valor",
        "description": "Increases damage dealt by your Legion while in battle by 1% every 10s, to a maximum of 3%.",
        "points": 0,
        "maxPoints": 1,
        "section": "top:3",
        "image": 37,
        "state": "locked",
        "type": "elite"
    },
    "16": {
        "name": "Luck of the Draw",
        "description": "When your Legion launches a counterattack in the field, they have a 10% chance to reduce all damage taken by 1% (up to a maximum of 3%) for 20s.",
        "points": 0,
        "maxPoints": 1,
        "section": "top:3",
        "image": 6,
        "state": "locked",
        "type": "elite"
    },
    "17": {
        "name": "War Banner",
        "description": "Increases maximum Legion Capacity by 300.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:4",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "18": {
        "name": "Fearless",
        "description": "Your Legion deals 1% more counterattack damage in the field.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:4",
        "image": 24,
        "state": "locked",
        "type": "normal"
    },
    "19": {
        "name": "Overall Speed",
        "description": "Increases the March Speed of your Legion's units by 2%.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:4",
        "image": 44,
        "state": "locked",
        "type": "normal"
    },
    "20": {
        "name": "Strength to Strength",
        "description": "Your Legion deals 0.3% more damage.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:5",
        "image": 37,
        "state": "locked",
        "type": "normal"
    },
    "21": {
        "name": "Avoidance",
        "description": "Your Legion gains Haste when taking Skill damage, increasing their March Speed by 4% for 5s. This effect can be triggered once every 10s.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:5",
        "image": 23,
        "state": "locked",
        "type": "normal"
    },
    "22": {
        "name": "Slow and Steady",
        "description": "When your Legion is intercepting an enemy, they gain Shelter, increasing their DEF by 1% for 10s. This effect can be triggered once every 30s.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:5",
        "image": 10,
        "state": "locked",
        "type": "normal"
    },
    "23": {
        "name": "Stubborn Fighter",
        "description": "When your Legion is in the field and their Unit Count is less than 50%, they deal 0.6% more damage.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:6",
        "image": 37,
        "state": "locked",
        "type": "normal"
    },
    "24": {
        "name": "Charge Ahead",
        "description": "Your Legion takes 0.5% less damage while marching.",
        "points": 0,
        "maxPoints": 5,
        "section": "top:6",
        "image": 6,
        "state": "locked",
        "type": "normal"
    },
    "25": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "top:6",
        "image": 10,
        "state": "locked",
        "type": "normal"
    },
    "26": {
        "name": "Rest and Repose",
        "description": "Grants healing (Healing Factor 60) when your Legion defeats enemy Legions in the field. This effect can be triggered once every 10s.",
        "points": 0,
        "maxPoints": 1,
        "section": "top:7",
        "image": 22,
        "state": "locked",
        "type": "core"
    },
    "27": {
        "name": "Blessing of Fury",
        "description": "When casting Rage Skills in the field, your Legion gains Vigor, increasing their HP by 10% for 5s. This effect can only be triggered once every 30s.",
        "points": 0,
        "maxPoints": 1,
        "section": "top:7",
        "image": 47,
        "state": "locked",
        "type": "core"
    },
    "28": {
        "name": "Magic HP",
        "description": "Increases the HP of your Legion's Magic units by 0.5%.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:0",
        "image": 50,
        "state": "locked",
        "type": "normal"
    },
    "29": {
        "name": "Fully Loaded",
        "description": "Your Legion deals 1% more normal attack damage when launching ranged attacks.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:1",
        "image": 15,
        "state": "locked",
        "type": "normal"
    },
    "30": {
        "name": "Foresight",
        "description": "When casting Rage Skills, your legion has a 25% chance to gain 15 Rage.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:1",
        "image": 45,
        "state": "locked",
        "type": "normal"
    },
    "31": {
        "name": "Magic Speed",
        "description": "Increases the March Speed of your Legion's Magic units by 3%.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:1",
        "image": 44,
        "state": "locked",
        "type": "normal"
    },
    "32": {
        "name": "All-Conquering",
        "description": "Your Legion takes 0.8% less normal attack damage.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:2",
        "image": 11,
        "state": "locked",
        "type": "normal"
    },
    "33": {
        "name": "Intimidation",
        "description": "Your Legion deals 0.8% more Hero Skill damage.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:2",
        "image": 36,
        "state": "locked",
        "type": "normal"
    },
    "34": {
        "name": "Magic ATK",
        "description": "Increases the ATK of your Legion's Magic units by 0.5%.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:2",
        "image": 17,
        "state": "locked",
        "type": "normal"
    },
    "35": {
        "name": "Energy Boost",
        "description": "8% chance to add an additional target to Rage Skills that deal damage to multiple targets.",
        "points": 0,
        "maxPoints": 1,
        "section": "left:3",
        "image": 14,
        "state": "locked",
        "type": "elite"
    },
    "36": {
        "name": "Magic Maelstrom",
        "description": "When your Legion consists entirely of Magic units, they gain DEF Mitigation before casting Rage Skills, mitigating 10% of the target's DEF for 5s. This effect can be triggered once every 30s.",
        "points": 0,
        "maxPoints": 1,
        "section": "left:3",
        "image": 34,
        "state": "locked",
        "type": "elite"
    },
    "37": {
        "name": "Orb of Protection",
        "description": "When casting Rage Skills, your Legion has a 25% chance to gain a shield (Shield Factor 40).",
        "points": 0,
        "maxPoints": 5,
        "section": "left:4",
        "image": 39,
        "state": "locked",
        "type": "normal"
    },
    "38": {
        "name": "Infantry Countertactics",
        "description": "Your Legion's Magic units deal 1% more damage to Infantry units.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:4",
        "image": 25,
        "state": "locked",
        "type": "normal"
    },
    "39": {
        "name": "Egosim",
        "description": "Your Legion takes 0.8% less Hero Skill damage.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:4",
        "image": 29,
        "state": "locked",
        "type": "normal"
    },
    "40": {
        "name": "Shelter of Compassion",
        "description": "Increases your Legion's shield granted by 1%.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:5",
        "image": 6,
        "state": "locked",
        "type": "normal"
    },
    "41": {
        "name": "Chant",
        "description": "Your Legion takes 0.5% more Hero Skill damage, but their ATK is increased by 0.5%.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:5",
        "image": 12,
        "state": "locked",
        "type": "normal"
    },
    "42": {
        "name": "Wither",
        "description": "When your Legion takes Skill damage, they have a 50% chance to inflict Magic DEF Break on the traget Legion, reducing their Magic DEF by 1% for 3s.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:5",
        "image": 48,
        "state": "locked",
        "type": "normal"
    },
    "43": {
        "name": "Cry Havoc",
        "description": "When the target Legion's Unit Count is less than 50%, your Legion deals 1.5% more Hero Skill damage.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:6",
        "image": 36,
        "state": "locked",
        "type": "normal"
    },
    "44": {
        "name": "Insult to Injury",
        "description": "When your Legion is only composed of Magic units, they deal 1% more damage through continuous damage effects.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:6",
        "image": 26,
        "state": "locked",
        "type": "normal"
    },
    "45": {
        "name": "Bolt from the Blue",
        "description": "When casting Rage Skills, your Legion gains Haste, increasing their March Speed by 4% for 5s. This effect can only be triggered once every 10s.",
        "points": 0,
        "maxPoints": 5,
        "section": "left:6",
        "image": 23,
        "state": "locked",
        "type": "normal"
    },
    "46": {
        "name": "Iconoclasm",
        "description": "When your Legion is composed entirey of magic units, you deal 2% more Hero Skill damage for 10s every time you gain a buff effect, up to 10%.",
        "points": 0,
        "maxPoints": 1,
        "section": "left:7",
        "image": 43,
        "state": "locked",
        "type": "core"
    },
    "47": {
        "name": "Elemental Boost",
        "description": "If your Legion consists entirely of Magic units, they gain Vigor when casting Rage Skills, increasing their HP by 15% for 4s. This effect can only be triggered once every 10s.",
        "points": 0,
        "maxPoints": 1,
        "section": "left:7",
        "image": 47,
        "state": "locked",
        "type": "core"
    },
    "48": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:0",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "49": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:1",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "50": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:1",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "51": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:1",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "52": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:2",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "53": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:2",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "54": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:2",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "55": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 1,
        "section": "right:3",
        "image": 41,
        "state": "locked",
        "type": "elite"
    },
    "56": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 1,
        "section": "right:3",
        "image": 41,
        "state": "locked",
        "type": "elite"
    },
    "57": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:4",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "58": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:4",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "59": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:4",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "60": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:5",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "61": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:5",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "62": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:5",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "63": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:6",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "64": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:6",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "65": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:6",
        "image": 41,
        "state": "locked",
        "type": "normal"
    },
    "66": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:7",
        "image": 41,
        "state": "locked",
        "type": "core"
    },
    "67": {
        "name": "Foresight2",
        "description": "...",
        "points": 0,
        "maxPoints": 5,
        "section": "right:7",
        "image": 41,
        "state": "locked",
        "type": "core"
    }
}

const defaultHero = {
    "name": "Waldyr",
    "faction": "League of Order",
    "nickname": "Ice Mage",
    "rarity": "epic",
    "talenttrees": {"top":"pvp","left":"magic","right":"skills"},
    "talents": defaultTalentTree
}

class Handler extends Component {
    constructor( props ) {
        super(props);
        this.state = {
            images: [],
            json: {
                raw: defaultHero,
                tree: JSON.stringify(defaultHero, null, 2)
            }
        }
    }

    componentDidMount() {
        fetch(`/getImages`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                ...prevState,
                images: data
            }));
        });
    }
    
    uploadImage() {
        fetch(`/newImage`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify({
                'image': 'data'
            })
        });
    }

    updateHero( json ) {
        fetch(`/updateHero`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                'data': json
            })
        }); 
    }

    jsonOnChangeText = (text) => {
		try {
			let tree = JSON.stringify(JSON.parse(text));
            
			this.setState(prevState => ({
                ...prevState,
				json: {
                    ...prevState.json,
                    tree: tree,
                    raw: JSON.parse(text) 
                }
			}));

            this.updateHero(JSON.parse(text))
		} catch (e) {
			// return false;
		}
	};

    render() {
        return (
            <div id="handler">
                <div id="talents-uploader">
                    <form method="post" enctype="multipart/form-data" action="/newImage">
                        <input type="file" id="files" name="files" multiple/>
                        <input type="text" id="type" name="type" />
                        <input type="text" id="meta" name="meta" />
                        <input type="submit" value="Submit" />
                    </form>
                </div>
                <div id="talents">
                    <div id="images">
                        {Object.keys( this.state.images ).map(image => {
                            return <img src={'data:image/png;base64, ' + this.state.images[image]} alt={image} title={image} />
                        })}
                    </div>
                </div>
                <div id="hero-builder">
                    <h1>Hero editor</h1>
                    <div id="jsoneditor">
                        <JSONEditorReact
                            indentation={4}
                            mode="code"
                            onChangeText={this.jsonOnChangeText}
                            json={this.state.json.raw}
                        />
                        <JSONEditorReact
                            schema={{type:'object'}}
                            mode="form"
                            text={this.state.json.tree}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Handler;