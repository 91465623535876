import React from 'react';
import { 
    CssBaseline,
    ThemeProvider, 
    createTheme,
    Grid,
    Button,
    TextField
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import './Alliance.scss';

const baseURL = 'https://cod.jsfour.dev/';
// const baseURL = 'http://192.168.10.15:80/_websites/';
const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    }
});
const tableColumns = [
    { field: 'playerName', headerName: 'Player Name', width: 200 },
    { field: 'power', headerName: 'Personal Power', width: 130 },
    { field: 'merits', headerName: 'Personal Merits', width: 130 },
    { field: 'donation', headerName: 'Alliance Donation', width: 150 },
    { field: 'build', headerName: 'Build Time', width: 130 },
    { field: 'help', headerName: 'Alliance Help', width: 130 },
    { field: 'overall', headerName: 'Overall Rank', width: 130 }
];

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

class Alliance extends React.Component {
    constructor( props ) {
        super(props);
        this.state = {
            rankings: {
                power: {},
                merits: {},
                donation: {},
                build: {},
                help: {},
                resource: {},
                overall: {},
                __all: []
            },
            graphs: {
                power: {
                    labels: [],
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                position: false,
                            },
                            title: {
                                display: true,
                                text: 'Personal Power',
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            },
                            y: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            }
                        }
                    },
                    data: {
                        labels: [],
                        datasets: []
                    }
                },
                merits: {
                    labels: [],
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                position: false,
                            },
                            title: {
                                display: true,
                                text: 'Personal Merits',
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            },
                            y: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            }
                        }
                    },
                    data: {
                        labels: [],
                        datasets: []
                    }
                },
                donation: {
                    labels: [],
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                position: false,
                            },
                            title: {
                                display: true,
                                text: 'Allliance Donation',
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            },
                            y: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            }
                        }
                    },
                    data: {
                        labels: [],
                        datasets: []
                    }
                },
                build: {
                    labels: [],
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                position: false,
                            },
                            title: {
                                display: true,
                                text: 'Build Time',
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            },
                            y: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            }
                        }
                    },
                    data: {
                        labels: [],
                        datasets: []
                    }
                },
                help: {
                    labels: [],
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                position: false,
                            },
                            title: {
                                display: true,
                                text: 'Alliance Help',
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            },
                            y: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            }
                        }
                    },
                    data: {
                        labels: [],
                        datasets: []
                    }
                },
                resource: {
                    labels: [],
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                position: false,
                            },
                            title: {
                                display: true,
                                text: 'Resource Assistance',
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            },
                            y: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            }
                        }
                    },
                    data: {
                        labels: [],
                        datasets: []
                    }
                },
                overall: {
                    labels: [],
                    options: {
                        maintainAspectRatio: false,
                        responsive: true,
                        plugins: {
                            legend: {
                                position: false,
                            },
                            title: {
                                display: true,
                                text: 'Overall Ranking',
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            },
                            y: {
                                grid: {
                                    color: 'rgba(255,255,255,0.4)'
                                }
                            }
                        }
                    },
                    data: {
                        labels: [],
                        datasets: []
                    }
                }
            },
            alliance: '',
            week: 1,
            key: null,
            accessKey: null
        }
    }

    async componentDidMount() {
        let alliance = this.props.alliance;
        let week = this.props.week;

        if ( alliance === null ) alliance = 'null';
        if ( week === null ) week = 1;
        
        document.title = `${alliance.toUpperCase()} - Alliance Rankings`;

        this.setState(prevState => ({
            ...prevState,
            alliance: alliance,
            week: week
        }));
    }

    componentWillUnmount() {

    }

    randomRGB() {
        var o = Math.round, r = Math.random, s = 255;
        return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',' + 1 + ')';
    }

    getPointsColor(points, category) {
        let minimum = 0;

        switch (category) {
            case 'donation':
                minimum = 50000;
                break;
            default:
                break;
        }

        if ( points >= minimum ) {
            return (`rgb(95, 209, 46)`);
        } else {
            return (`rgb(209, 46, 46)`);
        }
    }

    async getStats() {
        if ( !this.state.key ) return;

        return new Promise((resolve,reject) => {
            fetch(`${baseURL}/api/v1/stats.php?alliance=${this.state.alliance}&week=${this.state.week}&key=${this.state.key}`, {
                method: 'GET',
            })
            .then(response => response.json())
            .then(playersData => {
                Object.keys( playersData ).forEach((key, i) => {
                    this.setState(prevState => ({
                        ...prevState,
                        rankings: {
                            ...prevState.rankings,
                            __all: [
                                ...prevState.rankings.__all,
                                playersData[key]
                            ]
                        }
                    }));
                    
                    Object.keys(playersData[key]).forEach((category) => {
                        if ( category !== 'id' && category !== 'playerName' ) {
                            this.setState(prevState => ({
                                ...prevState,
                                graphs: {
                                    ...prevState.graphs,
                                    [category]: {
                                        ...prevState.graphs[category],
                                        data: {
                                            ...prevState.graphs[category].data,
                                            labels: [`Members`],
                                            datasets: [
                                                ...prevState.graphs[category].data.datasets,
                                                {
                                                    label: playersData[key]['playerName'],
                                                    data: [ playersData[key][category] ],
                                                    backgroundColor: this.getPointsColor(playersData[key][category], category)
                                                } 
                                            ]
                                        }
                                    }
                                }
                            }));
                        } 
                    });
                });
            });
        });
    }

    setKey( e ) {
        this.setState(prevState => ({
            ...prevState,
            key: e.target.value
        }));
    }

    login() {
        if ( this.state.key === undefined || this.state.key === null || this.state.key === '' || this.state.key.length === 0 ) return;

        fetch(`${baseURL}/api/v1/master.php`, {
            method: 'POST',
            body: JSON.stringify({
                'key': this.state.key
            })
        })
        .then(response => response.json())
        .then(data => {
            if ( data ) {
                this.setState(prevState => ({
                    ...prevState,
                    accessKey: data
                }), async () => {
                    await this.getStats();
                });
            }
        });
    }

    onKeyDown( e ) {
        if ( e.key === 'Enter' ) {
            this.login();
        }
    } 

    render() {
        return (
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                    { this.state.accessKey ?
                    <>
                        <div id="alliance">
                            <Grid container>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div className="chart-container">
                                        <Bar options={this.state.graphs.power.options} data={this.state.graphs.power.data}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div className="chart-container">
                                        <Bar options={this.state.graphs.merits.options} data={this.state.graphs.merits.data}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div className="chart-container">
                                        <Bar options={this.state.graphs.donation.options} data={this.state.graphs.donation.data}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div className="chart-container">
                                        <Bar options={this.state.graphs.build.options} data={this.state.graphs.build.data}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div className="chart-container">
                                        <Bar options={this.state.graphs.help.options} data={this.state.graphs.help.data}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>
                                    <div className="chart-container">
                                        <Bar options={this.state.graphs.resource.options} data={this.state.graphs.resource.data}/>
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <DataGrid
                                        rows={this.state.rankings.__all}
                                        columns={tableColumns}
                                        initialState={{
                                            pagination: {
                                                paginationModel: { page: 0, pageSize: 100 },
                                            },
                                        }}
                                        pageSizeOptions={[50, 100]}
                                        checkboxSelection
                                        // sortModel={[{field:"donation",sort:"desc"}]}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </>
                    : <>
                        <div id="login">
                            <Grid 
                                container 
                            >
                                <Grid item xs={12}>
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Key" 
                                        variant="outlined"
                                        fullWidth
                                        type="password"
                                        onChange={(e) => this.setKey(e)}
                                        onKeyDown={(e) => this.onKeyDown(e)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button onClick={() => this.login()} size="large" fullWidth variant="contained">Enter</Button>
                                </Grid>
                            </Grid>  
                        </div>
                    </> }
            </ThemeProvider>
        );
    }
}

export default Alliance;