import { Component } from 'react';

import TalentNormalSelected from './Images/talent_normal_selected.png';
import TalentNormalUnlocked from './Images/talent_normal_unlocked.png';
import TalentNormalLocked from './Images/talent_normal_locked.png';
import TalentCoreSelected from './Images/talent_core_selected.png';
import TalentCoreUnlocked from './Images/talent_core_unlocked.png';
import TalentCoreLocked from './Images/talent_core_locked.png';
import TalentEliteSelected from './Images/talent_elite_selected.png';
import TalentEliteUnlocked from './Images/talent_elite_unlocked.png';
import TalentEliteLocked from './Images/talent_elite_locked.png';
import TalentActive from './Images/talent_active.png';
import TalentEliteUnavailable from './Images/talent_elite_unavailable.png';
import TalentCoreUnavailable from './Images/talent_core_unavailable.png';

import './Talent.scss';

const talentTypes = {
    normal: {
        locked: TalentNormalLocked,
        unlocked: TalentNormalUnlocked,
        selected: TalentNormalSelected,
        active: TalentActive
    },
    core: {
        locked: TalentCoreLocked,
        unlocked: TalentCoreUnlocked,
        selected: TalentCoreSelected,
        active: TalentActive,
        unavailable: TalentCoreUnavailable
    },
    elite: {
        locked: TalentEliteLocked,
        unlocked: TalentEliteUnlocked,
        selected: TalentEliteSelected,
        active: TalentActive,
        unavailable: TalentEliteUnavailable
    }
}

class Talent extends Component {
    openTalent( e, id, imageID ) {
        this.props.openTalent(e, id, imageID);
    }

    updateTalent( e, id ) {
        let rightClicked = false;

        if ( e.nativeEvent.button !== 0 ) {
            rightClicked = true;
        }

        this.props.updateTalent(id, rightClicked, e.shiftKey);
    }

    render() {
        return (
            <>
                {
                    this.props.updateTalent
                    ? (
                        <div onContextMenu={(e) => e.preventDefault()} key={this.props.id} className={ `talent ${this.props.type} ${this.props.state}` } onMouseUp={(e) => this.updateTalent( e, this.props.id)} section={this.props.section} >
                            <img draggable="false" src={ this.props.image } className='talent_image' alt={this.props.image} />
                            <img draggable="false" src={ talentTypes[this.props.type][this.props.state] } className={ this.props.type } alt='Talent background' />
                            { this.props.active &&
                                <img draggable="false" src={ talentTypes[this.props.type]['active'] } className='talent-active' alt='Talent active border' />
                            }
                            { (this.props.state !== 'locked' && this.props.state !== 'unavailable') &&
                                <p>{this.props.points}/{this.props.maxPoints}</p>
                            }
                        </div>
                    ) : (
                        <div onContextMenu={(e) => e.preventDefault()} key={this.props.id} className={ 'talent ' + this.props.type } onMouseUp={(e) => this.openTalent( e, this.props.id, this.props.imageID )} section={this.props.section} >
                            <img draggable="false" src={ this.props.image } className='talent_image' alt={this.props.image} />
                            <img draggable="false" src={ talentTypes[this.props.type][this.props.state] } className={ this.props.type } alt='Talent background' />
                            { this.props.active &&
                                <img draggable="false" src={ talentTypes[this.props.type]['active'] } className='talent-active' alt='Talent active border' />
                            }
                            { (this.props.state !== 'locked' && this.props.state !== 'unavailable')  &&
                                <p>{this.props.points}/{this.props.maxPoints}</p>
                            }
                        </div>
                    )
                }
            </> 
        );
    }
}

export default Talent;