import { Component } from 'react';

import { BrowserRouter } from "react-router-dom";
import Router from "./Routes";

import './Assets/Styles/global.scss';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                {/* <Main> */}
                    <Router />
                {/* </Main> */}
            </BrowserRouter>
        );
    }
}

export default App;