import Cavalry from './Images/Cavalry.png';
import Foundation from './Images/Foundation.png';
import Gathering from './Images/Gathering.png';
import Infantry from './Images/Infantry.png';
import Magic from './Images/Magic.png';
import Marksman from './Images/Marksman.png';
import Overall from './Images/Overall.png';
import Peacekeeping from './Images/Peacekeeping.png';
import Precision from './Images/Precision.png';
import PvP from './Images/PvP.png';
import Skills from './Images/Skills.png';
import Support from './Images/Support.png';
import Tank from './Images/Tank.png';
import Garrison from './Images/Garrison.png';
import Control from './Images/Control.png';
import Engineering from './Images/Engineering.png';
import Mobility from './Images/Mobility.png';
import Rally from './Images/Rally.png';

import border from './Images/border.png';
import borderSelected from './Images/border_selected.png';

const all = {
    Cavalry,
    Foundation, 
    Gathering, 
    Infantry, 
    Magic, 
    Marksman, 
    Overall, 
    Peacekeeping, 
    Precision, 
    PvP, 
    Skills, 
    Support,
    Tank,
    Garrison,
    Control,
    Engineering,
    Mobility,
    Rally
}

export { 
    Cavalry,
    Foundation, 
    Gathering, 
    Infantry, 
    Magic, 
    Marksman, 
    Overall, 
    Peacekeeping, 
    Precision, 
    PvP, 
    Skills, 
    Support,
    Tank,
    Garrison,
    Control,
    Engineering,
    Mobility,
    Rally,
    border,
    borderSelected,
    all
};