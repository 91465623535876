import { Route, Routes, useParams, useSearchParams  } from "react-router-dom";

import TalentBuilder from "../Pages/TalentBuilder";
import Handler from "../Pages/Handler";
import Alliance from "../Pages/Alliance";
import TalentEditor from "../Pages/TalentEditor";
import Login from "../Pages/Login";

const HeroWrapper = () => {
    const { hero } = useParams();

    return <TalentBuilder hero={hero} />;
};

const AllianceWrapper = () => {
    const [ searchParams ] = useSearchParams();
    const { alliance } = useParams();
    
    return <Alliance alliance={alliance} week={searchParams.get('week')} />;
};

const routesConfig = [
    { path: '/', exact: true, component: <TalentBuilder /> },
    { path: '/handler', exact: true, component: <Handler /> },
    { path: '/login', exact: true, component: <Login /> },
    { path: '/alliance/:alliance?', exact: true, component: <AllianceWrapper /> },
    { path: '/talenteditor', exact: true, component: <TalentEditor /> },
    { path: '/hero/:hero?', exact: true, component: <HeroWrapper /> },
];

const Router = () => {
    const pageRoutes = routesConfig.map((route, i) => {
        return (
            <Route
                path={route.path}
                exact={route.exact}
                element={route.component}
            />
        );
    });

    return <Routes>{ pageRoutes }</Routes>;
};

export default Router;