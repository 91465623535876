import { Component } from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';

import Talent from '../../Components/Talent';
import * as talentTypes from '../../Components/TalentTypes';

import './TalentBuilder.scss';

const baseURL = 'https://cod.jsfour.dev';
// const baseURL = 'http://192.168.10.15:80/_websites';

class TalentBuilder extends Component {
    constructor( props ) {
        super(props);
        this.state = {
            talents: {
                "1": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:0",
                    "image": 0,
                    "state": "unlocked",
                    "type": "normal"
                },
                "2": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "3": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "4": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "5": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "6": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 3,
                    "section": "foundation:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "7": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "core",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "8": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:0",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "9": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "10": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "11": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "12": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "13": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "14": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "15": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "top:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "16": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "top:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "17": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "18": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "19": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "20": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "21": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "22": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "23": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "24": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "25": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "top:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "26": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "top:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "27": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "top:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "28": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:0",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "29": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "30": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "31": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "32": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "33": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "34": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "35": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "left:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "36": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "left:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "37": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "38": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "39": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "40": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "41": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "42": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "43": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "44": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "45": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "left:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "46": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "left:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "47": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "left:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "48": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:0",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "49": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "50": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "51": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:1",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "52": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "53": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "54": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:2",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "55": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "right:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "56": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 1,
                    "section": "right:3",
                    "image": 0,
                    "state": "locked",
                    "type": "elite"
                },
                "57": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "58": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "59": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:4",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "60": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "61": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "62": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:5",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "63": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "64": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "65": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:6",
                    "image": 0,
                    "state": "locked",
                    "type": "normal"
                },
                "66": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                },
                "67": {
                    "name": "Talent",
                    "description": "Description",
                    "points": 0,
                    "maxPoints": 5,
                    "section": "right:7",
                    "image": 0,
                    "state": "locked",
                    "type": "core"
                }
            },
            trees: {
                'top': {
                    text: 'Skills',
                    points: 0,
                    pointsToComplete: 32,
                    image: 'Skills'
                },
                'left': {
                    text: 'Skills',
                    points: 0,
                    pointsToComplete: 32,
                    image: 'Skills'
                },
                'right': {
                    text: 'Skills',
                    points: 0,
                    pointsToComplete: 32,
                    image: 'Skills'
                },
                'core': {
                    points: 0,
                    pointsToComplete: 1,
                    image: ''
                },
                'foundation': {
                    text: 'Foundation Talents',
                    points: 0,
                    pointsToComplete: 9,
                    image: 'Foundation'
                }
            },
            sections: {
                'foundation:0': {
                    points: 0,
                    maxPoints: 3,
                    requiredPoints: 3,
                    nextSection: ['foundation:1'],
                    previousSection: []
                },
                'foundation:1': {
                    points: 0,
                    maxPoints: 6,
                    requiredPoints: 3,
                    nextSection: ['foundation:2'],
                    previousSection: ['foundation:0']
                },
                'foundation:2': {
                    points: 0,
                    maxPoints: 9,
                    requiredPoints: 3,
                    nextSection: ['core'],
                    previousSection: ['foundation:1']
                },
                'core': {
                    points: 0,
                    maxPoints: 1,
                    requiredPoints: 1,
                    nextSection: ['left:0', 'top:0', 'right:0'],
                    previousSection: ['foundation:2']
                },
                'top:0': {
                    points: 0,
                    maxPoints: 5,
                    requiredPoints: 5,
                    nextSection: ['top:1'],
                    previousSection: ['core']
                },
                'top:1': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:2'],
                    previousSection: ['top:0']
                },
                'top:2': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:3'],
                    previousSection: ['top:1']
                },
                'top:3': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: ['top:4'],
                    previousSection: ['top:2']
                },
                'top:4': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:5'],
                    previousSection: ['top:3']
                },
                'top:5': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:6'],
                    previousSection: ['top:4']
                },
                'top:6': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['top:7'],
                    previousSection: ['top:5']
                },
                'top:7': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: [],
                    previousSection: ['top:6']
                },
                'left:0': {
                    points: 0,
                    maxPoints: 5,
                    requiredPoints: 5,
                    nextSection: ['left:1'],
                    previousSection: ['core']
                },
                'left:1': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:2'],
                    previousSection: ['left:0']
                },
                'left:2': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:3'],
                    previousSection: ['left:1']
                },
                'left:3': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: ['left:4'],
                    previousSection: ['left:2']
                },
                'left:4': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:5'],
                    previousSection: ['left:3']
                },
                'left:5': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:6'],
                    previousSection: ['left:4']
                },
                'left:6': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['left:7'],
                    previousSection: ['left:5']
                },
                'left:7': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: [],
                    previousSection: ['left:6']
                },
                'right:0': {
                    points: 0,
                    maxPoints: 5,
                    requiredPoints: 5,
                    nextSection: ['right:1'],
                    previousSection: ['core']
                },
                'right:1': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:2'],
                    previousSection: ['right:0']
                },
                'right:2': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:3'],
                    previousSection: ['right:1']
                },
                'right:3': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: ['right:4'],
                    previousSection: ['right:2']
                },
                'right:4': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:5'],
                    previousSection: ['right:3']
                },
                'right:5': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:6'],
                    previousSection: ['right:4']
                },
                'right:6': {
                    points: 0,
                    maxPoints: 15,
                    requiredPoints: 5,
                    nextSection: ['right:7'],
                    previousSection: ['right:5']
                },
                'right:7': {
                    points: 0,
                    maxPoints: 2,
                    requiredPoints: 1,
                    nextSection: [],
                    previousSection: ['right:6']
                }
                
            },
            zoom: 40,
            containerMovement: {
                down: false,
                mouseX: 0,
                mouseY: 0,
                pageX: 0,
                pageY: 0,
                containerOffsetX: 0,
                containerOffsetY: 0,
                speed: 4
            },
            heroSelect: {
                id: 0,
                name: 0,
                faction: 0,
                nickname: 0,
                rarity: 0
            },
            heroes: {},
            talentImages: [],
            activeTalent: {}
        }
    }

    __setTalent( tree, data ) {
        const talentsIDLookup = {
            "left": {
                0: 28,
                1: 29,
                2: 30,
                3: 31,
                4: 32,
                5: 33,
                6: 34,
                7: 35,
                8: 36,
                9: 37,
                10: 38,
                11: 39,
                12: 40,
                13: 41,
                14: 42,
                15: 43,
                16: 44,
                17: 45,
                18: 46,
                19: 47
            },
            "top": {
                0: 8,
                1: 9,
                2: 10,
                3: 11,
                4: 12,
                5: 13,
                6: 14,
                7: 15,
                8: 16,
                9: 17,
                10: 18,
                11: 19,
                12: 20,
                13: 21,
                14: 22,
                15: 23,
                16: 24,
                17: 25,
                18: 26,
                19: 27
            },
            "right": {
                0: 48,
                1: 49,
                2: 50,
                3: 51,
                4: 52,
                5: 53,
                6: 54,
                7: 55,
                8: 56,
                9: 57,
                10: 58,
                11: 59,
                12: 60,
                13: 61,
                14: 62,
                15: 63,
                16: 64,
                17: 65,
                18: 66,
                19: 67
            },
            "foundation": {
                0: 1,
                1: 2,
                2: 3,
                3: 4,
                4: 5,
                5: 6
            },
            "core": {
                0: 7
            }
        };
        
        if ( data.length === 20 || (data.length === 6 && tree === 'foundation') || (data.length === 1 && tree === 'core') ) {
            for (let i = 0; i < data.length; i++) {
                const talentID = talentsIDLookup[tree][i].toString();
                const talentPoints = parseInt(data[i]);
                
                if ( talentPoints > 0 ) {
                    this.updateTalent( talentID, false, false, true, talentPoints );
                }
            }
        }
    }
    
    setTalentsByUrl() {
        const urlQuery = new URLSearchParams(window.location.search);
        const [ left, right, top, foundation, core ] = [ urlQuery.get('left'), urlQuery.get('right'), urlQuery.get('top'), urlQuery.get('foundation'), urlQuery.get('core')];

        if ( left ) {
            this.__setTalent('left', left);
        }

        if ( top ) {
            this.__setTalent('top', top);
        }

        if ( right ) {
            this.__setTalent('right', right);
        }

        if ( foundation ) {
            this.__setTalent('foundation', foundation);
        }
        
        if ( core ) {
            this.__setTalent('core', core);
        }
    }

    componentDidMount() {
        window.addEventListener('wheel', this.handleWheel, {passive: false});
        window.addEventListener('scroll', ( event ) => event.preventDefault(), {passive: false});
        window.addEventListener('mouseup', this.handleMouseUp, {passive: false});
        window.addEventListener('mousedown', this.handleMouseDown, {passive: false});
        window.addEventListener('mousemove', this.handleMouseMove, {passive: false});
        
        fetch(`${baseURL}/api/v1/hero.php`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                ...prevState,
                heroes: data
            }));

            if ( this.props.hero ) {
                Object.keys( data ).forEach(key => {
                    if ( data[key].name.toLowerCase() === this.props.hero.toLowerCase() ) {
                        this.setState(prevState => ({
                            ...prevState,
                            talents: JSON.parse(data[key].talents),
                            heroSelect: {
                                id: data[key].id,
                                name: data[key].name,
                                faction: data[key].faction,
                                nickname: data[key].nick_name,
                                rarity: data[key].rarity
                            }
                        }));
            
                        let trees = JSON.parse(data[key].talent_trees);
            
                        Object.keys( trees ).forEach(tree => {
                            this.setState(prevState => ({
                                ...prevState,
                                trees: trees
                            }));
                        });

                        this.setTalentsByUrl();
                    }
                });
            }
        });

        fetch(`${baseURL}/api/v1/images.php`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                ...prevState,
                talentImages: data
            }));
        }); 
    }

    componentWillUnmount() {
        window.removeEventListener('wheel', this.handleWheel);
        window.removeEventListener('scroll', ( event ) => event.preventDefault());
        window.removeEventListener('mouseup', this.handleMouseUp);
        window.removeEventListener('mousedown', this.handleMouseDown);
        window.removeEventListener('mousemove', this.handleMouseMove);
    }

    handleMouseDown = ( event ) => {
        if ( event.target.id === 'root' || event.target.id === 'talent-tree' ) {
            this.setState(prevState => ({
                ...prevState,
                containerMovement: {
                    ...prevState.containerMovement,
                    down: true,
                    containerOffsetX: document.getElementById('talent-tree').getBoundingClientRect().left,
                    containerOffsetY: document.getElementById('talent-tree').getBoundingClientRect().top
                }
            }));
        }
    }

    handleMouseUp = ( event ) => {
        this.setState(prevState => ({
            ...prevState,
            containerMovement: {
                ...prevState.containerMovement,
                down: false,
                containerOffsetX: document.getElementById('talent-tree').getBoundingClientRect().left,
                containerOffsetY: document.getElementById('talent-tree').getBoundingClientRect().top
            }
        }));
    }

    handleMouseMove = ( event ) => {
        if ( this.state.containerMovement.down ) {
            let mouseX = this.state.containerMovement.mouseX;
            let mouseY = this.state.containerMovement.mouseY;
            
            if ( event.pageX < this.state.containerMovement.pageX ) {
                mouseX = mouseX - this.state.containerMovement.speed - this.state.zoom/40;
            } else if ( event.pageX > this.state.containerMovement.pageX) {
                mouseX = mouseX + this.state.containerMovement.speed + this.state.zoom/40;
            }

            if ( event.pageY < this.state.containerMovement.pageY ) {
                mouseY = mouseY - this.state.containerMovement.speed - this.state.zoom/40;
            } else if ( event.pageY > this.state.containerMovement.pageY) {
                mouseY = mouseY + this.state.containerMovement.speed + this.state.zoom/40;
            }

            let talentTreeElement = document.getElementById('talent-tree');
                talentTreeElement.style.transform = `translate(${ mouseX }px, ${ mouseY  }px)`; 

            this.setState(prevState => ({
                ...prevState,
                containerMovement: {
                    ...prevState.containerMovement,
                    pageX: event.pageX,
                    pageY: event.pageY,
                    mouseX: mouseX,
                    mouseY: mouseY
                }
            }));

            
        }
    }

    handleWheel = ( event ) => {
        if ( !event.srcElement.classList.contains('MuiButtonBase-root') ) {
            event.preventDefault();
        
            let zoom = this.state.zoom;
            
            if ( event.deltaY < 0 ) {
                if ( zoom !== 90 ) {
                    zoom = zoom + 10;
                }
            } else {
                if ( zoom !== 40 ) {
                    zoom = zoom - 10;
                }
            }

            this.setState(prevState => ({
                ...prevState,
                zoom: zoom
            }));

            document.getElementById('talent-tree').style.zoom = `${ zoom }%`;
        }   
    }

    updateSection( section, state ) {
        let talents = this.state.talents;
        let sections = this.state.sections;
        let nextSections = sections[section].nextSection;
        
        if ( state !== 'locked' && state !== 'unavailable' ) {
            for (let i = 0; i < nextSections.length; i++) {
                let nextSection = nextSections[i];

                Object.keys( talents ).forEach(( talent ) => {
                    let talentSection = talents[talent].section;
        
                    if ( talentSection === nextSection ) { 
                        if ( this.state.talents[talent].state === 'selected' ) return; 

                        this.setState(prevState => ({
                            ...prevState,
                            talents: {
                                ...prevState.talents,
                                [talent]: {
                                    ...prevState.talents[talent],
                                    state: state
                                }
                            }
                        }));
                    }
                });
            }
        } else {
            let pointsRemoved = {};

            const nextSectionLoop = ( section ) => {
                if ( sections[section] ) {
                    let nextSections = sections[section].nextSection;
                    let tree = section.replace(/:.*/gm, '');

                    if ( !pointsRemoved[tree] ) pointsRemoved[tree] = 0

                    for (let i = 0; i < nextSections.length; i++) {
                        let nextSection = nextSections[i];

                        Object.keys( talents ).forEach(( talent ) => {
                            let talentSection = talents[talent].section;

                            if ( talentSection === nextSection ) {
                                this.setState(prevState => ({
                                    ...prevState,
                                    talents: {
                                        ...prevState.talents,
                                        [talent]: {
                                            ...prevState.talents[talent],
                                            state: 'locked',
                                            points: 0
                                        }
                                    },
                                    sections: {
                                        ...prevState.sections,
                                        [talentSection]: {
                                            ...prevState.sections[talentSection],
                                            points: 0
                                        }
                                    }
                                }));

                                pointsRemoved[tree] = pointsRemoved[tree] + talents[talent].points;
                            }
                        });
    
                        nextSectionLoop(nextSection);
                    }
                }
            }

            nextSectionLoop(section);
            
            Object.keys( pointsRemoved ).forEach(tree => {
                this.setState(prevState => ({
                    ...prevState,
                    trees: {
                        ...prevState.trees,
                        [tree]: {
                            ...prevState.trees[tree],
                            points: this.state.trees[tree].points - pointsRemoved[tree]
                        }
                    }
                }));
            });
        }
    }

    updateTalent( id, rightClicked, shiftClicked, force, forcePoints ) {
        let section = this.state.talents[id].section;
        let tree = section.replace(/:.*/gm, '');
        let pointsToAdd = 1;
        
        if ( !rightClicked && (this.state.talents[id].state === 'locked' || this.state.talents[id].state === 'unavailable') && !force ) {
            return;
        }
        
        if ( this.state.sections[section].previousSection[0] && !force  ) {
            let previousSection = this.state.sections[this.state.sections[section].previousSection[0]];
        
            if ( previousSection.points < previousSection.requiredPoints ) {
                return;
            }
        }
        
        if ( shiftClicked ) {
            pointsToAdd =  this.state.talents[id].maxPoints - this.state.talents[id].points;
        }
        
        let talentPoints = this.state.talents[id].points + pointsToAdd;
        let sectionPoints = this.state.sections[section].points + pointsToAdd;
        let treePoints = this.state.trees[tree].points + pointsToAdd;

        let state = 'unlocked';
        
        if ( force ) {
            talentPoints = this.state.talents[id].points + forcePoints;
            sectionPoints = this.state.sections[section].points + forcePoints;
            treePoints = this.state.trees[tree].points + forcePoints;
        }
        
        if ( rightClicked ) {
            talentPoints = talentPoints - ( pointsToAdd + 1 );
            sectionPoints = sectionPoints - ( pointsToAdd + 1 );
            treePoints = treePoints - ( pointsToAdd + 1 );
        }

        if ( talentPoints > this.state.talents[id].maxPoints || talentPoints === -1 ) {
            return;
        }

        if ( this.state.talents[id].type === 'elite' || this.state.talents[id].type === 'core' ) {
            let talents = this.state.talents;
            let state = 'unavailable';
            
            if ( !talentPoints ) {
                state = 'unlocked';
            }

            Object.keys( talents ).forEach(talent => {
                if ( talents[talent].section === section && ( talents[talent].type === 'elite' || talents[talent].type === 'core' ) && id !== talent ) {
                    setTimeout(() => {
                        this.setState(prevState => ({
                            ...prevState,
                            talents: {
                                ...prevState.talents,
                                [talent]: {
                                    ...prevState.talents[talent],
                                    state: state
                                }
                            }
                        }));
                    }, 1);
                }
            });
        }
        
        if ( talentPoints > 0 ) {
            state = 'selected';
        }
        
        this.setState(prevState => ({
            ...prevState,
            talents: {
                ...prevState.talents,
                [id]: {
                    ...prevState.talents[id],
                    points: talentPoints,
                    state: state
                }
            },
            trees: {
                ...prevState.trees,
                [tree]: {
                    ...prevState.trees[tree],
                    points: treePoints
                }
            },
            sections: {
                ...prevState.sections,
                [section]: {
                    ...prevState.sections[section],
                    points: sectionPoints
                }
            }, 
            activeTalent: {
                [id]: true
            }
        }), () => {   
            if ( sectionPoints >= this.state.sections[section].requiredPoints && state ) {
                this.updateSection(section, 'unlocked');
            } else if ( rightClicked && sectionPoints < this.state.sections[section].requiredPoints ) {
                this.updateSection(section, 'locked');
            }

            this.setURLTalents();
        });
    }

    getTalents( tree ) {
        let talents = [];

        Object.keys( this.state.talents ).forEach(( id ) => {
            let talent = this.state.talents[id];
            let talentTree = talent.section.replace(/:.*/gm, '');
            
            if ( tree === talentTree && Object.keys(this.state.talentImages).length ) {
                talents.push(
                    <Talent
                        active={this.state.activeTalent[id]}
                        section={talent.section}
                        id={id}
                        type={talent.type}
                        state={talent.state}
                        points={talent.points}
                        maxPoints={talent.maxPoints}
                        updateTalent={ (id, rightClicked, shiftClicked) => this.updateTalent( id, rightClicked, shiftClicked ) }
                        image={'data:image/png;base64, ' + this.state.talentImages[talent.image]}
                    />
                );
            }
        });

        return talents;
    }

    selectHero( e ) {
        Object.keys( this.state.heroes ).forEach(hero => {
            if ( e.target.value === this.state.heroes[hero].id ) {
                window.history.replaceState(null, e.target.value, `/hero/${ this.state.heroes[hero].name.toLowerCase() }`);
            }
        });

        fetch(`${baseURL}/api/v1/hero.php?id=${ e.target.value }`, {
            method: 'GET',
            mode: 'cors'
        })
        .then(response => response.json())
        .then(data => {
            this.setState(prevState => ({
                ...prevState,
                talents: JSON.parse(data[0].talents),
                heroSelect: {
                    id: data[0].id,
                    name: data[0].name,
                    faction: data[0].faction,
                    nickname: data[0].nick_name,
                    rarity: data[0].rarity
                },
                activeTalent: {}
            }));

            let trees = JSON.parse(data[0].talent_trees);

            Object.keys( trees ).forEach(tree => {
                this.setState(prevState => ({
                    ...prevState,
                    trees: trees
                }));
            });
        });
    }

    setURLTalents() {
        let talents = this.state.talents;
        let trees = {
            'left': '',
            'top': '',
            'right': '',
            'foundation': '',
            'core': ''
        };
        let urls = [];
        let hero = this.state.heroSelect.name.toLowerCase();

        Object.keys( talents ).forEach((id) => {
            let section = talents[id].section;
            let tree = section.replace(/:.*/gm, '');

            trees[tree] = trees[tree] + talents[id].points;
        });

        Object.keys( trees ).forEach((tree) => {
            urls.push(`${ tree }=${ trees[tree] }`);
        });

        let URL = urls.join('&');
     
        window.history.pushState('', 'Title', `/hero/${hero}?` + URL);
    }
    
    render() {
        return (
            <div id="talent-builder">
                <div id="hero-select">
                    <FormControl variant="filled" >
                        <Select
                            labelId="hero-select-id"
                            value={this.state.heroSelect.id}
                            label="Hero"
                            onChange={(e) => this.selectHero(e)}
                            sx={{ m: 1, width: 300 }}
                        >
                            <MenuItem value={0}>Select Hero</MenuItem>
                            { Object.keys(this.state.heroes).map((hero) => (
                                <MenuItem value={this.state.heroes[hero].id}>{this.state.heroes[hero].name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> 
                </div>
                <div id="talent-tree">
                    {Object.keys( this.state.trees ).map((key) => {
                        return (
                            <div key={key} id={key}>
                                {
                                this.state.trees[key].text
                                    ? (
                                        this.state.trees[key].points >= this.state.trees[key].pointsToComplete
                                        ? (
                                            <div className={ 'talent-section-header talent-section-complete talent-type-wrapper-' + this.state.trees[key].image }>
                                                <div className='talent-section-image'>
                                                    <img src={talentTypes[this.state.trees[key].image]} alt="Talent type" className={ 'talent-type ' + this.state.trees[key].image } />
                                                    <img src={talentTypes.borderSelected} alt="Talent border" className='talent-border' />
                                                </div>
                                                <h1>{this.state.trees[key].text}</h1>
                                            </div>
                                        )
                                        : (
                                            this.state.trees[key].points > 0
                                            ? (
                                                <div className={ 'talent-section-header talent-section-selected talent-type-wrapper-' + this.state.trees[key].image }>
                                                    <div className='talent-section-image'>
                                                        <img src={talentTypes[this.state.trees[key].image]} alt="Talent type" className={ 'talent-type ' + this.state.trees[key].image } />
                                                        <img src={talentTypes.border} alt="Talent border" className='talent-border' />
                                                    </div>
                                                    <h1>{this.state.trees[key].text}</h1>
                                                </div>
                                            )
                                            : (
                                                <div className={ 'talent-section-header talent-type-wrapper-' + this.state.trees[key].image }>
                                                    <div className='talent-section-image'>
                                                        <img src={talentTypes[this.state.trees[key].image]} alt="Talent type" className={ 'talent-type ' + this.state.trees[key].image } />
                                                        <img src={talentTypes.border} alt="Talent border" className='talent-border' />
                                                    </div>
                                                    <h1>{this.state.trees[key].text}</h1>
                                                </div>
                                            )
                                        )
                                    )
                                    :  ( <></> )
                                }
                                <div className='talents'>
                                    { this.getTalents( key ) }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default TalentBuilder;